import request from '@/utils/request'

// 查询智驾地图信息列表
export function listMap(query) {
  return request({
    url: '/iot/map/list',
    method: 'get',
    params: query
  })
}

// 查询已下载好的智驾地图信息列表
export function listMapVehicle(query) {
  return request({
    url: '/iot/map/listVehicle',
    method: 'get',
    params: query
  })
}

// 下发开始下载地图指令
export function downloadStart(data) {
  return request({
    url: '/iot/map/download/start',
    method: 'post',
    data: data
  })
}

// 车端回馈中控开始下载地图指令ACK
export function downloadStartAck(query) {
  return request({
    url: '/iot/map/download/startAck',
    method: 'get',
    params: query
  })
}

// 下发中止下载地图指令
export function downloadInt(data) {
  return request({
    url: '/iot/map/download/int',
    method: 'post',
    data: data
  })
}

// 车端回馈中控中止地图指令ACK
export function downloadIntAck(query) {
  return request({
    url: '/iot/map/download/intAck',
    method: 'get',
    params: query
  })
}

// 下发切换地图指令
export function mapChange(data) {
  return request({
    url: '/iot/map/change',
    method: 'post',
    data: data
  })
}

// 车端回馈下发切换地图指令ACK
export function mapChangeAck(query) {
  return request({
    url: '/iot/map/changeAck',
    method: 'get',
    params: query
  })
}

// 下发继续下载地图指令
export function downloadGoOn(data) {
  return request({
    url: '/iot/map/download/goOn',
    method: 'post',
    data: data
  })
}

// 删除地图
export function mapDelete(data) {
  return request({
    url: '/iot/map/del',
    method: 'post',
    data: data
  })
}

// 删除地图ACK
export function mapDeleteAck(query) {
  return request({
    url: '/iot/map/delAck',
    method: 'get',
    params: query
  })
}

// 同步云端仓库
export function syncGitMap(data) {
  return request({
    url: '/iot/map/syncGitMap',
    method: 'post',
    data: data
  })
}

// 同步车端仓库
export function syncVehicleMap(data) {
  return request({
    url: '/iot/map/syncVehicleMap',
    method: 'post',
    data: data
  })
}

// 车端回馈中控同步车端地图指令ACK
export function syncVehicleMapAck(query) {
  return request({
    url: '/iot/map/syncVehicleMapAck',
    method: 'get',
    params: query
  })
}
