<template>
  <!-- 新增任务模板 -->
  <div>
    <vxe-modal
      v-model="ModalVisible"
      width="38%"
      height="auto"
      :title="title"
      :show-zoom="true"
      resize
      :lock-view="false"
      :mask="true"
      :position="position"
      transfer
      @close="handleCloseTaskModal"
      @hide="handleCloseTaskModal"
      class="send-task"
    >
      <a-spin :spinning="spinning">
        <!-- <div class="spin-content" v-if="spinning">
          {{ tips }}
        </div> -->
        <div>
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            layout="horizontal"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item label="模板名称" prop="taskName">
              <a-input v-model="form.taskName" placeholder="请输入" />
            </a-form-model-item>
            <a-form-model-item label="任务类型" prop="taskType">
              <span v-if="isEdit">{{ getTaskTypeName() }}</span>
              <a-radio-group v-else v-model="form.taskType" button-style="solid" @change="handleTaskTypeChange">
                <a-radio-button v-for="(d, index) in taskTypeOptions" :key="index" :value="d.dictValue">{{
                  d.dictLabel
                }}</a-radio-button>
              </a-radio-group>
            </a-form-model-item>
            <!-- 循迹任务 -->
            <template v-if="['0', '2'].indexOf(form.taskType) >= 0">
              <a-form-model-item label="地图ID" prop="mapId">
                <span v-if="isEdit">{{ form.mapId }}</span>
                <!-- <a-input v-else v-model="form.mapId" placeholder="请输入" /> -->
                <!-- <a-select
                  v-else
                  show-search
                  placeholder="请输入mapId模糊查找"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="handleMapSearch"
                  @change="handleMapChange"
                  :loading="loading"
                  v-model="form.mapId"
                >
                  <a-select-option v-for="d in mapArray" :key="d.mapId">
                    {{ d.mapId }}
                  </a-select-option>
                </a-select> -->
                <a-auto-complete
                  v-else
                  v-model="form.mapId"
                  @change="handleMapIdChange"
                  :data-source="mapIdArray"
                  placeholder="请输入mapId模糊查找"
                  allowClear
                />
              </a-form-model-item>
              <a-form-model-item label="地图版本" prop="mapVersion">
                <span v-if="isEdit">{{ form.mapVersion }}</span>
                <a-input v-else v-model="form.mapVersion" placeholder="请输入" allowClear />
              </a-form-model-item>
              <a-form-model-item label="循迹路径目录名称" prop="routeFolderName" v-if="form.taskType === '0'">
                <span v-if="isEdit">{{ form.routeFolderName }}</span>
                <a-input v-else v-model="form.routeFolderName" placeholder="请输入" />
              </a-form-model-item>
            </template>
            <a-form-model-item v-if="form.taskType === '1'" label="选择路线" prop="taskRouteId">
              <span v-if="isEdit">{{ form.routeName }}</span>
              <a-select
                v-else
                show-search
                placeholder="请输入路线名称模糊查找"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @search="handleRouteSearch"
                @change="handleRouteChange"
                :loading="loading"
                v-model="form.taskRouteId"
              >
                <a-select-option v-for="d in routeArray" :key="d.id">
                  {{ d.routeName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <template v-if="form.taskType === '2'">
              <a-form-model-item label="目标位置">
                <a-button @click="handleMapDraw" type="primary">去地图选点</a-button>
              </a-form-model-item>
              <a-form-model-item label="目标位置经度" prop="lon">
                <a-input type="number" v-model="form.lon" placeholder="请输入经度" />
              </a-form-model-item>
              <a-form-model-item label="目标位置纬度" prop="lat">
                <a-input type="number" v-model="form.lat" placeholder="请输入纬度" />
              </a-form-model-item>
            </template>
            <a-form-model-item label="任务地址" prop="address">
              <a-input v-model="form.address" placeholder="请输入任务地址" />
            </a-form-model-item>
            <a-form-model-item label="任务备注" prop="remark">
              <a-input v-model="form.remark" placeholder="请输入" />
            </a-form-model-item>
            <a-form-model-item label="任务里程(km)" prop="mileage">
              <a-input v-model="form.mileage" placeholder="请输入" />
            </a-form-model-item>
          </a-form-model>
          <dialog-bottom-bar
            primary-text="确定"
            second-text="取消"
            @onConfirm="submitForm"
            @onCancel="handleCancelTask"
          />
        </div>
      </a-spin>
    </vxe-modal>
    <GetLocationOnMap @onLocation="handleGetLocation" ref="mapRef" />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { listRoutePlanning } from '@/api/iot/routePlanning'
import { addVehicleTask, updateVehicleTask, getVehicleTask, createTask, createTaskAck } from '@/api/iot/vehicleTask'
import DialogBottomBar from '@/components/DialogBottomBar'
import TaskBase from './TaskBase.js'
import { listMapVehicle } from '@/api/iot/map'
import { AutoComplete } from 'ant-design-vue'
import GetLocationOnMap from './GetLocationOnMap'
export default {
  mixins: [TaskBase],
  name: 'CreateTaskTemplateModal',
  components: { DialogBottomBar, AAutoComplete: AutoComplete, GetLocationOnMap },

  props: {
    info: {
      type: Object,
      default: () => {}
    },
    position: {
      type: Object,
      default: () => {}
    },
    isShowModal: {
      type: Boolean,
      default: () => {}
    },
    vehicleId: {
      type: String,
      default: () => {}
    },
    taskForm: {
      type: Object,
      default: () => {}
    },
    withDynamic: {
      type: Boolean,
      default: () => {}
    }
  },
  data() {
    // 节流控制
    this.handleRouteSearch = debounce(this.handleRouteSearch, 500)
    this.handleMapSearch = debounce(this.handleMapSearch, 500)
    return {
      ModalVisible: this.isShowModal,
      confirmDisabled: true,
      form: {
        id: null,
        vehicleId: null,
        taskName: null,
        taskRouteId: '',
        remark: null,
        taskType: '1',
        mapId: '',
        mapVersion: '',
        lon: 0,
        lat: 0
      },
      rules: {
        taskName: [{ required: true, message: '模板名称不能为空', trigger: 'blur' }],
        taskRouteId: [{ required: true, message: '请选择路线名称', trigger: 'blur' }],
        mapId: [{ required: true, message: '地图ID不能为空', trigger: 'blur' }],
        mapVersion: [{ required: true, message: '地图版本不能为空', trigger: 'blur' }],
        routeFolderName: [{ required: true, message: '循迹路径目录名称不能为空', trigger: 'blur' }],
        lon: [{ required: true, message: '目标清洁点经度不能为空', trigger: 'change' }],
        lat: [{ required: true, message: '目标清洁点纬度不能为空', trigger: 'change' }]
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 17 },
      routeArray: [],
      loading: false,
      title: this.vehicleId + '-创建任务模板',
      // spinning: false,
      vehicleTaskConflicts: [],
      tips: '正在下发任务',
      isEdit: false,
      // 任务类型
      taskTypeOptions: [
        {
          dictLabel: 'LaneId任务',
          dictValue: '1'
        },
        {
          dictLabel: '循迹任务',
          dictValue: '0'
        }
        // {
        //   dictLabel: '固定点任务',
        //   dictValue: '2'
        // }
      ],

      timeOpen: false,
      taskArray: [],
      isExit: false,
      mapArray: []
    }
  },
  created() {
    this.getDictData()
    this.handleMapSearch('')
    this.setDynamicData()
  },
  mounted() {
    console.log('info', this.info)
    const businessState = this.info.businessState
    this.form.mapId = businessState && businessState.map_id !== '-' ? businessState.map_id : ''
    this.form.mapVersion = businessState && businessState.map_version !== '-' ? businessState.map_version : ''
    if (this.taskForm) {
      this.initTaskForm(this.taskForm)
    }
  },
  destroyed() {
    console.log('send task destroyed')
    this.clearTimeoutEx()
  },
  watch: {
    'info.vehicleId'(newVal, oldVal) {
      console.log('创建任务窗口关闭', newVal + '=' + oldVal)
      this.$emit('closeTaskModal')
      this.ModalVisible = false
    },
    taskForm(newVal, oldVal) {
      console.log('taskForm', newVal)
      if (newVal) {
        this.initTaskForm(newVal)
      }
    }
  },
  methods: {
    setDynamicData() {
      if (this.withDynamic) {
        this.taskTypeOptions.push({
          dictLabel: '固定点任务',
          dictValue: '2'
        })
      }
    },
    handleMapDraw() {
      console.log('handleMapDraw')
      this.$refs.mapRef.handleUpdate(this.form.lon * 1, this.form.lat * 1)
    },
    handleGetLocation(res) {
      console.log('handleGetLocation')
      this.form.lon = res.lng
      this.form.lat = res.lat
      this.$refs.form.clearValidate()
    },
    getDictData() {
      // 车端回传结果状态
      this.getDicts('iot_vehicle_remoteCtlResult').then((response) => {
        this.vehicleRemoteCtlResultOptions = response.data
      })
    },
    getTaskTypeName() {
      return this.selectDictLabel(this.taskTypeOptions, this.form.taskType)
    },
    handleTaskTypeChange(e) {
      this.form.taskType = e.target.value
      this.$refs.form.clearValidate()
    },
    initTaskForm(form) {
      this.isEdit = true
      this.title = this.vehicleId + '-修改任务模板'
      console.log('taskTemplateForm', form)
      if (!form.id) {
        this.form.taskType = null
        return
      }
      getVehicleTask(form.id).then((response) => {
        this.form = response.data
        this.form.routeName = form.routeName
        this.loading = false
        console.log('form', this.form)
        console.log('routerArray', this.routeArray)
      })
    },
    handleRouteSearch(value) {
      console.log('handleLaneSearch', value)
      const queryParam = {
        routeName: value
      }
      this.loading = true
      listRoutePlanning(queryParam).then((response) => {
        this.routeArray = response.rows
        this.loading = false
      })
    },
    // 路线改变
    handleRouteChange(value) {
      this.form.taskRouteId = value
      this.$emit('viewRoute', value)
    },
    handleMapSearch(value) {
      console.log('handleLaneSearch', value)
      const queryParam = {
        vehicleId: this.vehicleId,
        mapId: value
      }
      this.loading = true
      listMapVehicle(queryParam).then((response) => {
        const mapArray = response.data
        this.mapArray = mapArray.filter((p) => p.downloadStatus === 3)
        this.loading = false
        this.mapIdArray = this.mapArray.map((p) => p.mapId + '/' + p.mapVersion)
      })
    },
    handleMapIdChange(val) {
      if (!val) {
        this.form.mapId = ''
        this.form.mapVersion = ''
        return
      }
      const index = val.indexOf('/')
      if (index >= 0) {
        this.form.mapId = val.substring(0, index)
        if (val.length > index + 1) {
          this.form.mapVersion = val.substring(index + 1)
        }
      } else {
        this.form.mapVersion = ''
      }
    },
    // 路线改变
    handleMapChange(value) {
      const row = this.mapArray.find((p) => p.mapId === value)
      if (row) {
        this.form.mapVersion = row.mapVersion
      }
    },
    handleCloseTaskModal() {
      this.isExit = true
      this.clearTimeoutEx()
      this.$emit('closeTaskModal')
    },
    handleCancelTask() {
      this.$emit('closeTaskModal')
      this.ModalVisible = false
    },
    /** 提交按钮 */
    submitForm() {
      this.form.vehicleId = this.vehicleId
      if (this.form.taskType === '0' || this.form.taskType === '2') {
        delete this.form.taskRouteId
      } else {
        delete this.form.mapId
        delete this.form.mapVersion
        delete this.form.routeFolderName
      }
      console.log(this.form)
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isEdit) {
            updateVehicleTask(this.form).then((res) => {
              this.$emit('closeTaskModal')
              this.$emit('update')
              this.ModalVisible = false
            })
          } else {
            // this.spinning = true
            // 新增任务
            addVehicleTask(this.form)
              .then((response) => {
                const taskId = response.data
                // 下发任务给车端
                const formData = new FormData()
                formData.append('id', taskId)
                // 先调用状态接口，判断这个任务车端是否已创建
                this.$loading.show({
                  msg: '正在下发任务模板给车端'
                })
                getVehicleTask(taskId).then((response) => {
                  const data = response.data
                  const vehicleCreate = data.vehicleCreate
                  // 如果值为0代表车端已创建
                  if (vehicleCreate === 0) {
                    this.toEnd('success', '创建任务模板成功！')
                  } else {
                    // this.tips = '正在下发任务模板给车端'
                    createTask(formData)
                      .then((res) => {
                        if (this.isExit) {
                          return
                        }
                        // // 获取车端任务状态
                        // this.isClearTimeout = false
                        // // 打开获取任务状态计时器
                        // this.getStatusStartTickcount = new Date()
                        // this.getCreateTaskStatus(taskId)
                        this.taskIsRunning = true
                        this.isClearTimeout = false
                        this.taskAckBeginTickount = new Date()
                        const params = {
                          vehicleId: this.vehicleId
                        }
                        this.getTaskAck(createTaskAck, params, 'createTask')
                      })
                      // Promise reject时回调
                      .catch((e) => {
                        // this.spinning = false
                        this.$loading.hide()
                      })
                  }
                })
              })
              // Promise reject时回调
              .catch((e) => {
                this.$loading.hide()
              })
          }
        } else {
          return false
        }
      })
    },
    toEnd(infoType, result) {
      this.$loading.hide()
      let myModal = this.$successEx
      if (infoType === 'warn') {
        myModal = this.$warningEx
      } else if (infoType === 'error') {
        myModal = this.$errorEx
      }
      myModal(result, () => {
        // this.spinning = false
        this.clearTimeoutEx()
        this.$emit('closeTaskModal')
        this.$emit('update')
        this.ModalVisible = false
      })
    },
    clearTimeoutEx() {
      this.createTaskTimeoutId && clearTimeout(this.createTaskTimeoutId)
    }
  }
}
</script>
<style lang="less">
.send-task {
  .ant-form-item {
    margin-bottom: 11.2px !important;
  }
}
.task-conflict {
  height: 25px;
}
.spin-content {
  padding: 20px;
  padding-left: 70px;
  font-weight: 600;
}
.send-task {
  .ant-spin-blur {
    opacity: 1 !important;
  }
  .ant-spin {
    left: 0 !important;
    top: 30px !important;
    height: 0 !important;
  }
  .ant-spin-dot {
    left: 50px !important;
  }
}
</style>
